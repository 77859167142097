@font-face {
  font-family: 'Metropolis';
  src: local('Metropolis'), url(./fonts/Metropolis-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 500;
  src: local('Metropolis'), url(./fonts/Metropolis-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 600;
  src: local('Metropolis'), url(./fonts/Metropolis-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 700;
  src: local('Metropolis'), url(./fonts/Metropolis-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 800;
  src: local('Metropolis'), url(./fonts/Metropolis-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Metropolis';
  font-weight: 900;
  src: local('Metropolis'), url(./fonts/Metropolis-Black.otf) format('opentype');
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 1em;
}
div,
ul {
  display: flex;
  flex: 1;
}

#root {
  min-height: 100%;
}

ul {
}

.unset * {
  display: unset;
}
